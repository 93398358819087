<template>
  <div class="rank-report-chart">
    <vue-highcharts
      v-if="chartSeries.length > 0"
      type="chart"
      :options="preparedChartData"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"/>
  </div>
</template>
<script>

import {getFlagSpan, shortenDigits} from "@/utils/utils";

export default {
  name: 'RankBreakdownChart',
  props: {
    chartData: Array,
  },
  computed: {
    xAxisCategories() {
      if (this.chartData.length === 0) {
        return [];
      } else {
        return Object.keys(this.chartData[0]['data']);
      }
    },
    chartSeries() {
      let series = [];
      this.chartData.forEach((item) => {
        let it = {
          name: item.title,
          data: Object.values(item.data),
        };
        series.push(it);
      });

      return series;
    },
    preparedChartData() {
      return {
        chart: {
          type: 'column'
        },
        title: false,
        xAxis: {
          categories: this.xAxisCategories,
        },
        colors: [
          'var(--secondary-dark-green)',
          'var(--secondary-green)',
          'var(--secondary-light-green)',
          'var(--secondary-orange)',
          'var(--secondary-yellow)',
          'var(--neutral-400)',
        ],
        yAxis: [
          {
            title: {
              text: ''
            },
          },
          {
            linkedTo: 0,
            opposite: true,
            title: false,
          }
        ],
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 50,
          itemMarginBottom: 10,
          itemStyle: {
            fontWeight: 'normal',
          },
          symbolRadius: 1,
        },
        tooltip: {
          useHTML: true,
          shadow: false,
          backgroundColor: "#fff",
          borderColor: '#E1E8F2',
          shared: true,
          formatter() {
            let html = `<div class="rank-report-chart-tooltip">`;
            html += `<div class="title">${this.x}</div>`;
            html += `<table>`;
            this.points.forEach((point) => {
              html += `<tr>`;
              html += `<td><div class="circle" style="background-color: ${point.color};"></div></td>`;
              html += `<td>${point.series.name}</td>`;
              let y = shortenDigits(point.y);
              html += `<td>${y}</td>`;
              html += `</tr>`;
            });
            html += `</table>`;
            html += `</div>`;

            return html;
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          }
        },
        series: this.chartSeries,
      };
    },
  },
}
</script>
