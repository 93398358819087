<template>
  <div class="rank-breakdown-report rank-report">
    <div class="new-container">
      <div class="header">
        <h1 class="page-title mobile-hidden">
          {{ $route.meta.pageTitle }}
        </h1>
      </div>

      <div class="search-input-wrap">
        <live-search-with-presets :presets="myApps"
                                  v-if="selectedApp.id !== undefined"
                                  :current-country="countryForLiveSearch"
                                  :selected-app="selectedApp"
                                  store="APP_STORE"
                                  @changed="searchResultsAppClicked"
        />

        <country-selector ref="countrySelector"
                          v-if="countries.length > 0"
                          :all-countries="countries"
                          :selected-outer="selectedCountries"
                          :return-all-countries="true"
                          @changed="changeCountries"/>
        <default-spinner v-else/>
      </div>

      <div class="app-date-block">
        <div class="selected-app" v-if="selectedApp.info">
          <span class="logo">
            <app-image
              :width="36"
              :height="36"
              :src="selectedApp.info?.logo"
            />
          </span>
          <div class="selected-title">
            <a :href="selectedApp.info?.store_link" target="_blank">
              <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
            </a>
            <span>{{ selectedApp.info?.title }}</span>
          </div>
          <img v-if="isGP"
               src="@/assets/images/icons/google_play.png"
               alt="Store icon"
               class="store-icon"
               width="22"
               height="22">
          <img v-else
               src="@/assets/images/icons/app_store.png"
               alt="Store icon"
               class="store-icon"
               width="22"
               height="22">

        </div>
        <datepicker-with-range-old
          v-if="dateFilter"
          :to-right="true"
          :date-filter="dateFilter"
          :min-date="minDate"
          :max-date="maxDate"
          :columns="2"
          :ranges="[7, 30, 90, 180]"
          @date-filter-changed="applyDatePicker"/>
      </div>

      <div class="filters-block">
        <filters-new :filters="filterConfigs"
                     ref="filters"
                     @apply-filters="filtersApplied"/>

        <custom-dropdown :min-width="'182px'" classes="source-dropdown" :close-dropdown="closeConfigDropdown">
          <template v-slot:header>
            <span>{{ source === 'ranking' ? 'Ranking keywords' : 'Tracking keywords'}}</span>
          </template>
          <template v-slot:content>
            <div class="select-options">
              <div class="option" @click="changeSource('ranking')">
                <div class="circle" :class="{active: source === 'ranking'}"></div><div>Ranking keywords</div>
              </div>
              <div class="option"
                   v-if="isTrackingSourceEnabled"
                   @click="changeSource('tracking')">
                <div class="circle" :class="{active: source === 'tracking'}"></div><div>Tracking keywords</div>
              </div>
              <custom-tooltip v-else
                              width="200px"
                              direction-vertical="bottom">
                <template v-slot:slotTrigger>
                  <div class="option disabled">
                    <div class="circle"></div><div>Tracking keywords</div>
                  </div>
                </template>
                <template v-slot:slotContent v-if="!isTrackingSourceEnabled">
                  Tracked keywords are available only for your apps
                </template>
              </custom-tooltip>
            </div>
          </template>
        </custom-dropdown>

      </div>

      <div class="content">
        <template v-if="dataIsLoading">
          <progress-indicator>Data is loading...</progress-indicator>
        </template>
        <server-error v-else-if="showTimeoutError"
                      button-text="Contact us for help"
                      @clicked="openIntercom">
          <template v-slot:title>
            Data Overflow…
          </template>
          <template v-slot:text>
            <p>
              Due to the large volume of data received for this application, generating the report caused an error.
            </p>
            <p>
              <b>
                Please decrease the time period
                <span v-if="selectedCountries.length > 1 || selectedCountries.length === 0">
                  or reduce the number of countries
                </span>
              </b>
              to generate a report for this application.
            </p>
            <p>
              <i>We are working to prevent this from happening in the future.</i>
            </p>
          </template>
        </server-error>
        <template v-else>
          <div v-if="noDataTitle" class="no-data-block">
              <span>
                <span class="logo">
                  <app-image
                    :width="28"
                    :height="28"
                    :src="selectedApp.info?.logo"
                  />
                </span>
                <span class="app-title">{{ selectedApp.info?.title }}</span>
                {{ noDataTitle }}
              </span>
          </div>
          <template v-else>
            <rank-breakdown-chart :chart-data="chartData"></rank-breakdown-chart>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import DefaultSpinner from "@/components/UI/DefaultSpinner/index.vue";
import CountrySelector from "@/views/Reports/components/CountrySelector/index.vue";
import LiveSearchWithPresets from "@/components/Dropdowns/LiveSearchWithPresets/index.vue";
import {formatDate, getDefaultMinDate, reverseDayMonthDate} from "@/utils/utils";
import {mapGetters} from "vuex";
import {fetchPromiseAllSettled, httpRequest} from "@/api";
import myApps from "@/api/modules/my-apps";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import {GenerateQueryUrl} from "@/utils/factories";
import axios from "axios";
import RankBreakdownChart from "@/views/Reports/RankBreakdown/components/RankBreakdownChart/index.vue";
import FiltersNew from "@/components/UI/FiltersComponent/FiltersNew.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import ServerError from "@/components/ServerError/index.vue";

export default {
  name: 'RankBreakdownReport',
  components: {
    ServerError,
    CustomDropdown,
    FiltersNew,
    RankBreakdownChart,
    ProgressIndicator,
    DatepickerWithRangeOld,
    AppImage,
    LiveSearchWithPresets,
    CountrySelector,
    DefaultSpinner,
    SvgIcon
  },
  data() {
    return {
      chartData: [],
      myApps: [],
      selectedApp: {},
      selectedCountries: null,
      allCountries: [],
      countries: [],
      dateFilter: null,
      minDate: new Date(),
      maxDate: new Date(),
      dataIsLoading: true,
      source: 'ranking',
      appliedFilters: {},
      cancelTokens: [],
      unfilteredTotal: 0,
      trekkingKeywordsTotal: 0,
      showTimeoutError: false,
      closeConfigDropdown: 0,
      rankingKeywordsLimit: 500,
    };
  },
  created() {
    this.minDate = getDefaultMinDate();
    this.maxDate.setDate((new Date()).getDate() - 1);
    this.setDefaultDates();

    this.setParamsFromUrl();
  },
  async mounted() {
    if (this.selectedCountries === null) {
      this.selectedCountries = [];
      if (this.currentCountry) {
        this.selectedCountries = [this.currentCountry.code];
      }
    }
    if (this.currentApp?.id && this.selectedApp.id === undefined) {
      this.selectedApp = {...this.currentApp};
    }

    await this.fetchCountries();
    await this.fetchMyApps();
  },
  methods: {
    setParamsFromUrl() {
      let queryString = window.location.search;
      if (queryString !== '') {
        let urlParams = new URLSearchParams(queryString);
        this.dateFilter.start = new Date(reverseDayMonthDate(urlParams.get('date_from')));
        this.dateFilter.end = new Date(reverseDayMonthDate(urlParams.get('date_to')));
        this.source = urlParams.get('source');
        this.selectedCountries = urlParams.getAll('country_codes[]');

        let urlParamsObject = Object.fromEntries(urlParams.entries());
        Object.keys(urlParamsObject).forEach((param) => {
          if (param.includes('filter')) {
            this.appliedFilters[param] = urlParamsObject[param];
          }
        });

        this.selectedApp = {
          id: urlParamsObject.app_id
        };
      }
    },
    openIntercom() {
      Intercom('showNewMessage');
    },
    async filtersApplied(e) {
      if (this.isFreeUser) {
        this.openPlansModal();
      } else if(JSON.stringify(this.appliedFilters) !== JSON.stringify(e)) {
        this.appliedFilters = {...e};
      }
    },
    changeSource(source) {
      if (source === 'tracking') {
        if (!this.isTrackingSourceEnabled) {
          return;
        }
        if (this.isFreeUser) {
          this.openPlansModal();
          return;
        }
      }

      this.source = source;
    },
    async prepareCountryGroups() {
      const rankingCounts = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.reports.RANKING_COUNTS + '?app_id=' + this.selectedApp.id
      );
      const countries = this.selectedCountries.length
        ? this.selectedCountries
        : (Object.keys(rankingCounts).length > 0 ? Object.keys(rankingCounts) : this.allCountries);
      let groups = [];
      let group = [];
      let count = 0;
      countries.forEach((code) => {
        const countryCount = rankingCounts[code];
        if ((count + countryCount) < this.rankingKeywordsLimit) {
          count += countryCount;
          group.push(code);
        } else {
          if (group.length > 0) {
            groups.push(group);
            group = [];
          }
          count = countryCount;
          group.push(code);
        }
      });
      if (group.length > 0) {
        groups.push(group);
      }

      if (groups.length === 1 && this.selectedCountries.length === 0) {
        return [];
      } else {
        return groups;
      }
    },
    async prepareQueries() {
      this.cancelTokens.forEach((token) => {
        token.cancel();
      });
      this.dataIsLoading = true;

      const groups = await this.prepareCountryGroups();
      let queryArray = [];
      let baseUrl = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.RANK_BREAKDOWN_REPORT + this.subRequestUrl;
      groups.forEach((codes) => {
        let url = baseUrl;
        codes.forEach((code) => {
          url += '&country_codes[]=' + code;
        });
        queryArray.push({
          url: url,
          method: 'GET',
        });
      });
      if (groups.length === 0) {
        queryArray.push({
          url: baseUrl,
          method: 'GET',
        });
      }
      let tokens = [];
      queryArray.forEach((query) => {
        let token = axios.CancelToken.source();
        tokens.push(token);
        query.opts = {cancelToken: token.token};
      });
      this.cancelTokens = tokens;

      return queryArray;
    },
    async fetchChartData() {
      if (this.requestUrl !== null) {
        this.dataIsLoading = true;
        this.closeConfigDropdown++;
        const queryArray = await this.prepareQueries();
        const responses = await fetchPromiseAllSettled(queryArray);
        if (responses.success) {
          let data = responses.data;
          let result = data.shift();
          data.forEach((response) => {
            if (response.data === undefined || result.data === undefined) {
              this.showTimeoutError = true;
              this.dataIsLoading = false;
              return;
            }

            result.unfiltered_total += response.unfiltered_total;
            result.trekking_keywords_total += response.trekking_keywords_total;
            Object.keys(response.data).forEach((topKey) => {
              Object.keys(response.data[topKey].data).forEach((date) => {
                result.data[topKey].data[date] += response.data[topKey].data[date];
              })
            });
          });

          this.chartData = Object.values(result.data);
          this.dataIsLoading = false;
          this.unfilteredTotal = result.unfiltered_total;
          this.trekkingKeywordsTotal = result.trekking_keywords_total;
          this.showTimeoutError = false;
        } else {
          console.error(responses);
        }
      }
    },
    applyDatePicker(event) {
      if (this.isFreeUser) {
        this.openPlansModal();
      } else {
        let tempFilterObj = {
          start: event?.start,
          end: event?.end,
        }
        this.dateFilter = {...tempFilterObj};
      }
    },
    setDefaultDates() {
      this.dateFilter = {...this.getLastDaysRange(30)};
    },
    getLastDaysRange(days) {
      const nowStart = new Date();
      const nowEnd = new Date();
      return {
        start: nowStart.setDate(nowStart.getDate() - days - 1),
        end: nowEnd.setDate(nowEnd.getDate() - 1),
      }
    },
    async fetchMyApps() {
      this.myApps = await myApps.getAllMyApps();
    },
    async fetchCountries() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES + '?app_id=' + this.selectedApp.id;
      let countries = await httpRequest('GET', url);
      if (this.countries.length === 0 && countries instanceof Array && countries.length > 0) {
        this.countries = countries;
      }
    },
    async searchResultsAppClicked(item) {
      this.selectedApp = {...item};
    },
    changeCountries(codes, isAll) {
      if (isAll) {
        this.selectedCountries = [];
        this.allCountries = codes;
      } else {
        this.selectedCountries = codes;
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'userSubscription',
    ]),
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    isMyApp() {
      return this.myApps.some((item) => {
        return +item.id === +this.selectedApp.id;
      });
    },
    noDataTitle() {
      if (this.unfilteredTotal > 0) {
        return null;
      }

      if (this.source === 'ranking') {
        return 'doesn’t have ranking keywords for selected countries.'
      } else {
        if (this.trekkingKeywordsTotal === 0) {
          return 'doesn’t have tracked keywords for selected countries.'
        } else {
          return 'doesn’t have tracked keywords with rank for selected countries.'
        }
      }
    },
    requestUrl() {
      if (this.selectedApp.id === undefined) {
        return null;
      }

      let url = this.subRequestUrl;
      this.selectedCountries.forEach((code) => {
        url += '&country_codes[]=' + code;
      });

      return url;
    },
    subRequestUrl() {
      if (this.selectedApp.id === undefined) {
        return null;
      }

      return '?app_id=' + this.selectedApp.id
        + '&date_from=' + formatDate(this.dateFilter.start, 'digits-dash')
        + '&date_to=' + formatDate(this.dateFilter.end, 'digits-dash')
        + '&source=' + this.source
        + GenerateQueryUrl(this.appliedFilters);
    },
    isGP() {
      return this.selectedApp.store?.key === 'GOOGLE_PLAY';
    },
    filterConfigs() {
      return [
        {
          type: 'range',
          title: 'Popularity',
          filter: 'range_sap',
          minLimit: 0,
          maxLimit: 100,
          selectedValue: {
            min: this.appliedFilters['filter[range_sap][from]'] ?? null,
            max: this.appliedFilters['filter[range_sap][to]'] ?? null,
          },
        },
      ];
    },
    isTrackingSourceEnabled() {
      return this.selectedApp.id && this.isMyApp;
    },
    countryForLiveSearch() {
      let countryCode = null;
      if (this.selectedCountries.length === 1) {
        countryCode = this.selectedCountries[0];
      } else if (this.selectedCountries.length > 1 && this.selectedCountries.includes(this.currentCountry?.code)) {
        countryCode = this.currentCountry.code;
      }

      let fullCountry = this.countries.find((country) => country.code === countryCode);

      return {
        code: countryCode,
        name: fullCountry?.name,
        id: fullCountry?.id,
      };
    },
  },
  watch: {
    currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.selectedApp = this.currentApp;
      }
    },
    async selectedApp(newVal, oldVal) {
      if (newVal.id && (newVal.id !== oldVal.id || newVal.info === undefined)) {
        let appData = await myApps.getApp(this.selectedApp.id, this.currentCountry.code);
        this.selectedApp.info = appData.app_info;
        this.selectedApp.store = {
          key: appData.app_info.store
        };
        if (!this.isMyApp && this.source === 'tracking') {
          this.source = 'ranking';
        }
      }
    },
    requestUrl() {
      this.fetchChartData();
      if (window.history.replaceState) {
        window.history.replaceState({url: this.requestUrl}, '', this.requestUrl);
      }
    },
  },
}
</script>

<style src="./../styles.scss" lang="scss"></style>
<style lang="scss" scoped>
  .rank-breakdown-report .content .rank-report-chart {
    padding-left: 10px;
    margin-bottom: 40px;
  }
</style>